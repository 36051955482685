import moment from 'moment'
import { Leaves } from './definition.extensions'

declare global {
  interface Array<T> {
    tap(callbackfn: (array: T[]) => void): Array<T>
    unique(): Array<T>
    compact(): Array<T>
    sortByKey(key: keyof T, order?: 'asc' | 'desc'): Array<T>
    sortByKeyPath(key: Leaves<T>, order?: 'asc' | 'desc'): Array<T>
    sortByDateKey(key: keyof T, order?: 'asc' | 'desc'): Array<T>
    findMap<V>(predicate: (item: T, idx: number, arr: T[]) => V): V
    get first(): T
    get last(): T
  }
}

export {}

Array.prototype.findMap = function (predicate) {
  for (let index = 0; index < this.length; index++) {
    const result = predicate(this[index], index, this)
    if (result != null) {
      return result
    }
  }
  return null
}

Array.prototype.tap = function (callbackfn) {
  callbackfn(this)
  return this
}

Array.prototype.unique = function () {
  const result = []
  this.forEach((elem) => {
    if (!result.includes(elem)) {
      result.push(elem)
    }
  })
  return result
}

Array.prototype.compact = function () {
  return this.filter((item) => !!item)
}

Array.prototype.sortByKey = function (key: string, order: 'asc' | 'desc' = 'asc') {
  const dir = order === 'asc' ? 1 : -1
  return this.sort((a, b) => {
    if (a[key] < b[key]) {
      return dir * -1
    } else if (a[key] > b[key]) {
      return dir * 1
    }
    return 0
  })
}

Array.prototype.sortByKeyPath = function (key: string, order: 'asc' | 'desc' = 'asc') {
  const dir = order === 'asc' ? 1 : -1
  const path = key.split('.')

  const valueByPath = (path: string[], object: any) =>
    path.reduce((acc, curr) => {
      if (!acc) {
        return acc
      }
      return acc[curr]
    }, object)

  return this.sort((a, b) => {
    const aValue = valueByPath(path, a)
    const bValue = valueByPath(path, b)
    if (aValue < bValue) {
      return dir * -1
    } else if (aValue > bValue) {
      return dir * 1
    }
    return 0
  })
}

Array.prototype.sortByDateKey = function (key: string, order: 'asc' | 'desc' = 'asc') {
  const dir = order === 'asc' ? 1 : -1
  return this.sort((a, b) => {
    const dateA = moment(a[key])
    const dateB = moment(b[key])

    if (dateA.isBefore(dateB)) {
      return dir * -1
    } else if (dateA.isAfter(dateB)) {
      return dir * 1
    }
    return 0
  })
}

if (typeof Array.prototype.first === 'undefined') {
  Object.defineProperty(Array.prototype, 'first', {
    get: function first() {
      if (this.length === 0) {
        return null
      }
      return this[0]
    },
  })
}

if (typeof Array.prototype.last === 'undefined') {
  Object.defineProperty(Array.prototype, 'last', {
    get: function last() {
      if (this.length === 0) {
        return null
      }
      return this[this.length - 1]
    },
  })
}
