declare global {
  interface String {
    capital(): string
    toCamelCase(): string
    trimTrailing(str: string): string
    toInt(): number
    zeroPad(): string
    nonBreakingSpaces(): string
  }
}

String.prototype.capital = function () {
  return (this.slice(0, 1).toUpperCase() || '') + (this.slice(1).toLowerCase() || '')
}

String.prototype.toCamelCase = function () {
  return this.replace(/\W+(.)/g, function (_, chr) {
    return chr.toUpperCase()
  })
}

String.prototype.trimTrailing = function (str) {
  return this.replace(new RegExp(`${str}$`), '')
}

String.prototype.toInt = function () {
  return parseInt(this.replace(/[^0-9]+/g, '')) || 0
}

String.prototype.zeroPad = function () {
  return parseInt(this.toString()).zeroPad()
}

String.prototype.nonBreakingSpaces = function () {
  return this.replace(/ /g, ' ')
}

export {}
