declare global {
  interface Number {
    inRange(rangeA: number, rangeB: number): boolean
    diff(other: number): number
    clamp(min: number, max: number): number
    toLocale(): string
    toDecimalLocale(decimals: number): string
    zeroPad(): string
    toArray(): Number[]
    times(callback: (index: number, length: Number) => void): void
  }
}

/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * (x * 255).clamp(0, 255)
 *
 * @param {Number} min The lower boundary of the output range
 * @param {Number} max The upper boundary of the output range
 * @returns A number in the range [min, max]
 * @type Number
 */
Number.prototype.clamp = function (min: number, max: number) {
  return Math.min(Math.max(this as number, min), max)
}

Number.prototype.toLocale = function () {
  return this.toLocaleString('sv').replace(/\u00A0/g, ' ')
}

Number.prototype.toDecimalLocale = function (decimals: number) {
  return Number(this.toFixed(decimals)).toLocale()
}

Number.prototype.zeroPad = function () {
  if (isNaN(this as any)) {
    return '00'
  }
  return Number(this) < 10 ? `0${this}` : this.toString()
}

Number.prototype.toArray = function () {
  return new Array(this).fill(Number(null))
}

Number.prototype.times = function (callback) {
  for (let index = 0; index < Number(this); index++) {
    callback(index, this)
  }
}

Number.prototype.diff = function (other) {
  return Math.abs((this as number) - other)
}

Number.prototype.inRange = function (rangeA: number, rangeB: number) {
  const min = Math.min.apply(Math, [rangeA, rangeB])
  const max = Math.max.apply(Math, [rangeA, rangeB])
  return Number(this) >= min && Number(this) <= max
}

export {}
