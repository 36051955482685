module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["de","en","sv"],"defaultLanguage":"sv","siteUrl":"https://pixel-that.se","redirect":false,"i18nextOptions":{"fallbackLng":"sv","supportedLngs":["de","en","sv"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/:slug","getLanguageFromPath":true,"excludeLanguages":["sv"]}]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
